import Model from '@/shared/classes/model'
import IHseRiskTemplateResponse from "@/shared/modules/hse-risk-templates/hse-risk-template-response.interface";
import IHseRiskCategoryResponse from "@/shared/modules/hse-risk-categories/hse-risk-category-response.interface";
import HseRiskCategory from "@/shared/modules/hse-risk-categories/hse-risk-category.model";

export default class HseRiskTemplate extends Model<IHseRiskTemplateResponse> {
  hseRiskCategoryUuid!: string
  hseRiskCategory!: IHseRiskCategoryResponse
  hseRiskCategoryName!: string
  isVisible!: boolean
  name!: string
  barriers!: any
  isCompanyTemplate!: boolean
  hseRiskTemplateUuid!: string
  isAssigned!: boolean

  transform(data: IHseRiskTemplateResponse): void {
    super.transform(data)

    this.hseRiskCategoryUuid = data.hseRiskCategoryUuid
    this.hseRiskCategoryName = data.hseRiskCategory?.name
    this.isVisible = data.isVisible
    this.name = data.name
    this.barriers = data.barriers
    this.isCompanyTemplate = Boolean(data.isCompanyTemplate)
    this.hseRiskTemplateUuid = data.hseRiskTemplateUuid
    this.isAssigned = data.isAssigned

    this.hseRiskCategory = this.setRelation(HseRiskCategory, data.hseRiskCategory)
  }
}
