











import { Component, Vue } from "vue-property-decorator";
import __ from "@/shared/helpers/__";
import PageHeader from "@/admin/components/PageHeader.vue";
import FormBase from "@/shared/classes/form/form-base";
import { HttpMethod } from "@/shared/helpers/requests.helper";
import Field, { FieldSizes } from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import RiskTemplateForm from "@/admin/components/risk-templates/RiskTemplateForm.vue";
import HseRiskTemplate from "@/shared/modules/hse-risk-templates/hse-risk-templates.model";
import { getLanguagesByVersion, getNodeApiUrlByVersion } from "@/config";
import localeHelper from "@/shared/helpers/locale.helper";
import ArrayField from "@/shared/classes/form/fields/array-field";
import { CompanyRoutes } from "@/shared/router/company";
import IResponse from "@/shared/interfaces/modules/response.interface";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import baseHttp from "@/shared/http";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import { getVersion } from "@/shared/helpers/version.helper";

@Component({
  components: { RiskTemplateForm, PageHeader },
  methods: { __ }
})
export default class RiskAssessmentsEdit extends Vue {
  form: FormBase | null = null
  http = baseHttp
  isEditingCompanyTemplate: boolean = true
  loaded: boolean = false

  async created() {
    const setupForm = ({ entry }: { entry: HseRiskTemplate }) => {
      const valuesToInject: Record<string, any> = {
        companyUuid: this.company.uuid,
        isVisible: true,
      }

      let endpoint = `${getNodeApiUrlByVersion()}/company-hse-risk-templates`
      if (this.isEditingCompanyTemplate) endpoint += `/${this.$router.currentRoute.params.uuid}`
      else valuesToInject.hseRiskTemplateUuid = this.$router.currentRoute.params.uuid

      this.form = new FormBase()
        .setModel(HseRiskTemplate)
        .setEntry(entry)
        .setEndpoint(endpoint)
        .setMethod(this.isEditingCompanyTemplate ? HttpMethod.PATCH : HttpMethod.POST)
        .setTranslatable(true)
        .setCurrentLanguage(getLanguagesByVersion([localeHelper.getLocale()])[0])
        .setFields([
          new SearchableField()
            .setKey("hseRiskCategoryUuid")
            .setEntryKey("hseRiskCategoryUuid")
            .isRequired()
            .setTitle(__("admin.components.risk-templates.form.category"))
            .loadItems({
              endpoint: `${getNodeApiUrlByVersion()}/hse-risk-categories`,
              value: "uuid",
              title: "name",
              perPage: 20
            }),
          new Field()
            .setType(FieldTypes.textArea)
            .setKey(`name.${localeHelper.getLocale()}`)
            .setTitle(__("admin.components.risk-templates.form.risk-title"))
            .setSize(FieldSizes.half)
            .setRequired(localeHelper.getLocale() === getVersion()),
          new Field()
            .setType(FieldTypes.textArea)
            .setKey("name")
            .setTranslatable(true)
            .setTitle(__("admin.components.risk-templates.form.risk-title"))
            .setSize(FieldSizes.half)
            .setRequired(localeHelper.getLocale() !== getVersion()),
          new ArrayField()
            .setKey("barriers")
            .setDense(true)
            .setDeleteButtonProps({ color: "transparent", elevation: 0, class: "mt-5" })
            .setAddButtonProps({ isIcon: true, class: "justify-end mr-12" })
            .setJustifyChildren("justify-space-between")
            .setChildren([
              new Field()
                .setType(FieldTypes.textArea)
                .setKey(`title.${localeHelper.getLocale()}`)
                .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
                .setClasses("ml-4")
                .setSize(FieldSizes.half)
                .setRequired(localeHelper.getLocale() === getVersion()),
              new Field()
                .setType(FieldTypes.textArea)
                .setKey("title")
                .setTranslatable(true)
                .setTitle(__("admin.components.risk-templates.form.risk-barrier-title"))
                .setClasses("ml-4")
                .setSize(FieldSizes.half)
                .setRequired(localeHelper.getLocale() !== getVersion()),
            ]),
        ])
        .setValidate(true, { onlyRegionLangRequired: true })
        .setShowDefaultValidationError(true)
        .setSubmit({
          text: this.isEditingCompanyTemplate ? __("general.update") : __("general.create")
        })
        .setInjectValues(valuesToInject)
        .setOnSuccess(this.onSuccess)
    }

    this.http.get(`${getNodeApiUrlByVersion()}/company-hse-risk-templates/${this.$router.currentRoute.params.uuid}?hasTranslations=true`)
      .then((response: IResponse<IModelResponse>) => {
        this.isEditingCompanyTemplate = true
        setupForm({ entry: response.data })
        this.loaded = true
      })
      .catch(() => {})

    this.http.get(`${getNodeApiUrlByVersion()}/hse-risk-templates/${this.$router.currentRoute.params.uuid}?hasTranslations=true`)
      .then((response: IResponse<IModelResponse>) => {
        this.isEditingCompanyTemplate = false
        setupForm({ entry: response.data });
        this.loaded = true
      })
      .catch(() => {})
  }

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.riskAssessmentsIndexTabTemplates })
  }

  private onSuccess(data: any): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.risk-assessments.edit.form.on-success'),
    })
    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany];
  }
}
